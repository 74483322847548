import React from 'react';
import Title from '../../title';
import './styles/styles.css';
import './styles/@styles.css';

function Tutorial () {

    return(
        <div className='tutorial' id='development'>
            <div className='tutorial-container'>
                <Title text='От простого к сложному'/>
                <div className='tutorial-container__content'>
                    <div className='tutorial-container__content-item'>
                        <div className='tutorial-container__content-item__title'>
                            <span>Какие уроки включены в план для новичков?</span>
                        </div>
                        <div className='tutorial-container__content-item__description'>
                            <span>
                                В план для новичков входят уроки по постановке
                                ног и рук, контролю сцепления, балансу тела и
                                мотоцикла, преодолению препятствий и многому другому!
                            </span>
                        </div>
                    </div>
                    <div className='tutorial-container__content-item'>
                        <div className='tutorial-container__content-item__title'>
                            <span>Что включает план для опытных спортсменов?</span>
                        </div>
                        <div className='tutorial-container__content-item__description'>
                            <span>
                                План для опытных спортсменов включает подготовку
                                к соревнованиям, совершенствование мастерства и доступ
                                к уникальным трекам для оттачивания навыков.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tutorial;
