import React, {useEffect, useState} from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import {firebaseConfig} from "../../action/firebaseConfig";
import {Link, useNavigate} from "react-router-dom";
import './styles/styles.css';
import './styles/@styles.css';

// Инициализация Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

function Auth () {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email, password);
            setLoggedIn(true);
            localStorage.setItem("loggedIn", "true");
        } catch (error) {
            let errorMessage = "Произошла ошибка при входе";
            if (error.code === 'auth/invalid-credential') {
                errorMessage = 'Неверный email или пароль';
            }
            setError(errorMessage);
            console.log(error)
        }
    }

    useEffect(() => {
        // Проверка состояния аутентификации при загрузке компонента
        const isLoggedIn = localStorage.getItem("loggedIn") === "true";
        if (isLoggedIn) {
            setLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (loggedIn) {
            navigate('/moderation');
        }
    }, [loggedIn, navigate]);

    return(
        <div className='auth'>
            <div className='auth-container'>
                <form className='auth-container__content'>
                    <span>Авторизация<br/><Link to='/'>Enduro Crimea</Link> dashboard</span>
                    <div className='auth-container__content-inputs'>
                        <div className='auth-container__content-inputs__input email'>
                            <input
                                placeholder='E-mail'
                                type='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='auth-container__content-inputs__input password'>
                            <input
                                placeholder='Пароль'
                                type='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    {error && <p>{error}</p>}
                    <div className='auth-container__content-login'>
                        <button onClick={handleLogin}>Войти</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Auth;
