import React, { useState } from 'react';
import ControlCarousel from '../../assets/images/icons/control_carousel.svg';

function CarouselVideo({ slides, setCurrentIndex }) {
    const [currentIndex, setLocalCurrentIndex] = useState(0);

    const prevSlide = () => {
        setLocalCurrentIndex((prevIndex) => {
            const newIndex = prevIndex === 0 ? slides.length - 1 : prevIndex - 1;
            setCurrentIndex(newIndex);
            return newIndex;
        });
    };

    const nextSlide = () => {
        setLocalCurrentIndex((prevIndex) => {
            const newIndex = prevIndex === slides.length - 1 ? 0 : prevIndex + 1;
            setCurrentIndex(newIndex);
            return newIndex;
        });
    };

    return (
        <div className="carousel">
            <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {slides.map((slide, index) => (
                    <div className="carousel-inner__item" key={index}>
                        {slide}
                    </div>
                ))}
            </div>
            <button className="carousel-button prev" onClick={prevSlide}>
                <img src={ControlCarousel} alt="Previous" />
            </button>
            <button className="carousel-button next" onClick={nextSlide}>
                <img src={ControlCarousel} alt="Next" />
            </button>
        </div>
    );
}

export default CarouselVideo;
