import React from 'react';
import MHeader from "./header";
import ControlPanel from "./control_panel";

function Moderation () {

    return(
        <div>
            <MHeader/>
            <ControlPanel/>
        </div>
    )
}

export default Moderation;
