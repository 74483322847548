import React from 'react';
import './styles/styles.css';

function Popup({ show, message, onClose }) {
    if (!show) {
        return null;
    }

    return (
        <div className="popup">
            <div className="popup-content">
                <span>{message}</span>
                <button onClick={onClose}>Хорошо</button>
            </div>
        </div>
    );
}

export default Popup;
