import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import Add from "../../../assets/images/icons/add.svg";
import Delete from "../../../assets/images/icons/delete_white.svg";
import './styles/styles.css';
import './styles/@styles.css';

function MBestMoments() {
    const [moments, setMoments] = useState([]);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [deletingMomentId, setDeletingMomentId] = useState(null);

    useEffect(() => {
        const fetchMoments = async () => {
            try {
                const snapshot = await firebase.firestore().collection('moments').orderBy('timestamp', 'desc').get();
                const momentsList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setMoments(momentsList);
            } catch (error) {
                console.error('Ошибка при получении моментов: ', error);
            }
        };

        fetchMoments();
    }, [uploading]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 30 * 1024 * 1024) {
                setError('Размер файла не должен превышать 30 МБ.');
                setFile(null);
            } else {
                setFile(selectedFile);
                setError('');
            }
        }
    };
    const handleUpload = async () => {
        if (!file) {
            setError('Пожалуйста, выберите фото.');
            return;
        }
        setUploading(true);
        const storageRef = firebase.storage().ref();
        try {
            const randomFileName = uuidv4();
            const fileRef = storageRef.child(`moments/${randomFileName}`);
            await fileRef.put(file);
            const fileUrl = await fileRef.getDownloadURL();
            await firebase.firestore().collection('moments').add({
                media: fileUrl,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            });
            console.log('Файл успешно загружен и добавлен в коллекцию moments!');
        } catch (error) {
            console.error('Ошибка при загрузке файла: ', error);
            setError('Ошибка при загрузке файла: ' + error.message);
        } finally {
            setUploading(false);
            setFile(null);
            setModalOpen(false);
        }
    };
    const handleDeleteClick = (momentId) => {
        setDeletingMomentId(momentId);
        setIsConfirmingDelete(true);
    };
    const handleDelete = async () => {
        try {
            const moment = moments.find(m => m.id === deletingMomentId);
            const storageRef = firebase.storage().refFromURL(moment.media);
            await storageRef.delete();
            console.log(`Фото из Storage успешно удалено: ${moment.media}`);

            await firebase.firestore().collection('moments').doc(deletingMomentId).delete();
            console.log(`Фото с id ${deletingMomentId} успешно удалено из Firestore`);

            setMoments(prevMoments => prevMoments.filter(m => m.id !== deletingMomentId));
        } catch (error) {
            console.error('Ошибка при удалении фото: ', error);
        } finally {
            setIsConfirmingDelete(false);
        }
    };

    return (
        <div className='mmoments'>
            <div className='mmoments-container'>
                <div className='mtitle'>
                    <span>Фотоальбом</span>
                    <div className='mvehicles-container__add'>
                        <div className='mvehicles-container__add-btn'>
                            <button onClick={() => setModalOpen(true)}>
                                <span>Добавить</span>
                                <img src={Add} alt='Add' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='mmoments-container__content'>
                    {moments.map(moment => (
                        <div key={moment.id} className='mmoments-container__content-item'>
                            <div className='mmoments-container__content-item__container'>
                                <img src={moment.media} alt={`Moment ${moment.id}`} />
                                <button onClick={() => handleDeleteClick(moment.id)}><img src={Delete} alt='Delete' /></button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {modalOpen && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content__inputs'>
                            <h3>Добавить новое фото</h3>
                            <div className='modal-content__inputs-input file'>
                                <input
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className='modal-content__inputs-rules'>
                                <span>
                                    Рекомендуемое расширение 1200 х 620
                                </span>
                            </div>
                            <div className='error-bm'>
                                <p>{error}</p>
                            </div>
                        </div>
                        <div className='modal-content__btns'>
                            <div className='modal-content__btns-btn cancel'>
                                <button onClick={() => setModalOpen(false)}>Отменить</button>
                            </div>
                            <div className='modal-content__btns-btn add'>
                                <button onClick={handleUpload} disabled={uploading}>
                                    {uploading ? 'Загрузка...' : 'Добавить'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isConfirmingDelete && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content__inputs'>
                            <h3>Вы уверены, что хотите удалить фото?</h3>
                        </div>
                        <div className='modal-content__btns delete'>
                            <div className='modal-content__btns-btn cancel'>
                                <button onClick={() => setIsConfirmingDelete(false)}>Отменить</button>
                            </div>
                            <div className='modal-content__btns-btn add'>
                                <button onClick={handleDelete}>Удалить</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MBestMoments;
