import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export const firebaseConfig = {
    apiKey: "AIzaSyDw5qPmOVJtjFzLEtvXU2vAPi7t2w0-kdU",
    authDomain: "enduro-4eda8.firebaseapp.com",
    projectId: "enduro-4eda8",
    storageBucket: "enduro-4eda8.appspot.com",
    databaseURL: "https://enduro-4eda8-default-rtdb.europe-west1.firebasedatabase.app",
    messagingSenderId: "479974029274",
    appId: "1:479974029274:web:02b913ce1cece2501132e3",
    measurementId: "G-LTE8ZB8DYK"
};

// Инициализация Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// Получение доступа к Firestore
const firestore = firebase.firestore();
const storage = firebase.storage();

export default firestore;

