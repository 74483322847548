import React, {useEffect, useState} from 'react';
import firestore from '../../../action/firestore';
import Title from "../../title";
import Card from "./card";
import './styles/styles.css';
import './styles/@styles.css';

function Price () {
    const [objects, setObjects] = useState([]);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const serviceCollection = await firestore.collection('price').get();
                const serviceList = serviceCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                serviceList.sort((a, b) => parseInt(a.id) - parseInt(b.id));
                setObjects(serviceList);
            } catch (error) {
                console.error('Error fetching prices: ', error);
            }
        };

        fetchPrices();
    }, []);

    return(
        <div className='price' id='tariffs'>
            {objects.length > 0 ? (
                <div className='price-container'>
                    <Title text='Цены и Услуги'/>
                    <div className='price-container__content'>
                        {objects.map((object) => (
                            <div className='price-container__content-card' key={object.id}>
                                <Card object={object}/>
                            </div>
                        ))}
                        {[8, 5, 2].map((count) => (
                            Array.from({ length: count }, (_, index) => (
                                <div key={index} className='empty-item'></div>
                            ))
                        ))}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default Price;
