import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from "./components/landing";
import Moderation from "./components/moderation";
import Authorization from "./components/authorization";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
  return (
      <>
        <Router>
            <Routes>
                <Route path='/' element={<Landing/>} />
                <Route path='/auth' element={<Authorization/>} />
                <Route
                    path='/moderation'
                    element={
                        <PrivateRoute>
                            <Moderation />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </Router>
      </>
  );
}

export default App;
