import React from 'react';
import {useNavigate} from "react-router-dom";
import Logo from '../../../assets/images/logo.svg';
import VK from '../../../assets/images/icons/network/vk.svg';
import TG from '../../../assets/images/icons/network/tg.svg';
import YT from '../../../assets/images/icons/network/yt.svg';
import './styles/styles.css';
import './styles/@styles.css';

function Footer() {
    const navigate = useNavigate();

    const handleNavigation = (path, id) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    const offset = 30;
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }, 100);
        }
    };

    return(
        <div className='footer'>
            <div className='footer-container'>
                <div className='footer-container__content'>
                    <div className='footer-container__content-logo'>
                        <img
                            src={Logo}
                            alt='Logo'
                        />
                    </div>
                    <div className='footer-container__content-links'>
                        <span onClick={() => handleNavigation('/', 'about')}>Наш клуб</span>
                        <span onClick={() => handleNavigation('/', 'team')}>Команда</span>
                        <span onClick={() => handleNavigation('/', 'tariffs')}>Тарифы</span>
                        <span onClick={() => handleNavigation('/', 'technique')}>Техника</span>
                        <span onClick={() => handleNavigation('/', 'development')}>Развитие</span>
                        <span onClick={() => handleNavigation('/', 'moments')}>Фотоальбом</span>
                    </div>
                    <div className='footer-container__content-networks'>
                        <a href='https://vk.com/enduro_crimea_crew' target='_blank' rel='noreferrer'>
                            <img src={VK} alt='VK'/>
                        </a>
                        <a href='https://t.me/MaxShachnev' target='_blank' rel='noreferrer'>
                            <img src={TG} alt='TG'/>
                        </a>
                        <a href='https://www.youtube.com/channel/UCHUyWmcVlyNAGMwWOsw2klg' target='_blank' rel='noreferrer'>
                            <img src={YT} alt='YT'/>
                        </a>
                    </div>
                </div>
                <div className='footer-container__copyright'>
                    <span>© 2024 Enduro Crimea</span>
                </div>
            </div>
        </div>
    )
}

export default Footer;
