import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import { getAuth, signOut } from 'firebase/auth';
import './styles/styles.css';
import './styles/@styles.css';

function MHeader () {
    const navigate = useNavigate();
    const handleLogout = async () => {
        const auth = getAuth();
        await signOut(auth);
        localStorage.removeItem("loggedIn");
        navigate('/auth');
    };

    return(
        <div className='mheader'>
            <div className='mheader-container'>
                <div className='mheader-container__logo'>
                    <Link to='/'>
                        <span><span>Enduro Crimea<br/></span>dashboard</span>
                    </Link>
                </div>
                <div className='mheader-container__logout'>
                    <button onClick={handleLogout}><span>Выход</span></button>
                </div>
            </div>
        </div>
    )
}

export default MHeader;
