import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import Add from '../../../assets/images/icons/add.svg';
import Edit from '../../../assets/images/icons/edit.svg';
import Delete from '../../../assets/images/icons/delete_white.svg';
import './styles/styles.css';
import './styles/@styles.css';

function MVehicles() {
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    const [uploading, setUploading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [editingVehicle, setEditingVehicle] = useState(null);
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [vehicleToDelete, setVehicleToDelete] = useState(null);

    useEffect(() => {
        const fetchVehicles = async () => {
            const snapshot = await firebase.firestore().collection('vehicles').get();
            const vehiclesList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setVehicles(vehiclesList);
        };

        fetchVehicles();
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileType = selectedFile.type;
            const validTypes = ['image/jpeg', 'image/png'];
            const maxSize = 30 * 1024 * 1024; // 30MB

            if (!validTypes.includes(fileType)) {
                setError('Пожалуйста, выберите файл формата JPEG или PNG.');
                setFile(null);
                return;
            }

            if (selectedFile.size > maxSize) {
                setError('Размер файла не должен превышать 30MB.');
                setFile(null);
                return;
            }

            setError('');
            setFile(selectedFile);
        }
    };

    const handleUpload = async () => {
        if (!description) {
            setError('Пожалуйста, предоставьте описание.');
            return;
        }

        if (!file && !editingVehicle) {
            setError('Пожалуйста, предоставьте фото.');
            return;
        }

        setUploading(true);
        const storageRef = firebase.storage().ref();
        try {
            let fileUrl = editingVehicle ? editingVehicle.urlMedia : '';

            if (file) {
                if (editingVehicle) {
                    const oldFileRef = firebase.storage().refFromURL(editingVehicle.urlMedia);
                    await oldFileRef.delete();
                }

                const randomFileName = uuidv4();
                const fileRef = storageRef.child(`vehicles/${randomFileName}`);
                await fileRef.put(file);
                fileUrl = await fileRef.getDownloadURL();
            }

            if (editingVehicle) {
                await firebase.firestore().collection('vehicles').doc(editingVehicle.id).update({
                    urlMedia: fileUrl,
                    description: description
                });

                setVehicles(vehicles.map(vehicle =>
                    vehicle.id === editingVehicle.id ? { ...vehicle, urlMedia: fileUrl, description: description } : vehicle
                ));
                setEditingVehicle(null);
            } else {
                const docRef = await firebase.firestore().collection('vehicles').add({
                    urlMedia: fileUrl,
                    description: description
                });
                const newVehicle = { id: docRef.id, urlMedia: fileUrl, description: description };
                setVehicles([...vehicles, newVehicle]);
            }

            console.log('Файл успешно загружен!');
        } catch (error) {
            console.log('Ошибка при загрузке файла: ' + error.message);
            setError('Ошибка при загрузке файла: ' + error.message);
        } finally {
            setUploading(false);
            setFile(null);
            setDescription('');
            setModalOpen(false);
        }
    };

    const confirmDelete = (vehicle) => {
        setVehicleToDelete(vehicle);
        setIsConfirmingDelete(true);
    };

    const handleDelete = async () => {
        const fileRef = firebase.storage().refFromURL(vehicleToDelete.urlMedia);
        try {
            await fileRef.delete();
            await firebase.firestore().collection('vehicles').doc(vehicleToDelete.id).delete();
            setVehicles(vehicles.filter(v => v.id !== vehicleToDelete.id));
            console.log('Объект и файл успешно удалены!');
        } catch (error) {
            console.log('Ошибка при удалении объекта или файла: ' + error.message);
            setError('Ошибка при удалении объекта или файла: ' + error.message);
        } finally {
            setIsConfirmingDelete(false);
            setVehicleToDelete(null);
        }
    };

    const openEditModal = (vehicle) => {
        setEditingVehicle(vehicle);
        setDescription(vehicle.description);
        setModalOpen(true);
    };

    return (
        <div className='mvehicles'>
            <div className='mvehicles-container'>
                <div className='mtitle'>
                    <span>Техника</span>
                    <div className='mvehicles-container__add'>
                        <div className='mvehicles-container__add-btn'>
                            <button onClick={() => setModalOpen(true)}>
                                <span>Добавить</span>
                                <img src={Add} alt='Add' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='mvehicles-container__content'>
                    {vehicles.map((vehicle) => (
                        <div key={vehicle.id} className='mvehicles-container__content-item'>
                            <div className='mvehicles-container__content-item__container'>
                                <div className='mvehicles-container__content-item__container-media'>
                                    <img src={vehicle.urlMedia} alt="Vehicle" />
                                </div>
                                <div className='mvehicles-container__content-item__container-description'>
                                    <span>{vehicle.description}</span>
                                </div>
                            </div>
                            <div className='mvehicles-container__content-item__btns'>
                                <button onClick={() => openEditModal(vehicle)}><img src={Edit}/></button>
                                <button onClick={() => confirmDelete(vehicle)}><img src={Delete}/></button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {modalOpen && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content__inputs'>
                            {editingVehicle ? (
                                <h3>Редактирование</h3>
                            ) : (
                                <h3>Добавить новую технику</h3>
                            )}
                            <div className='modal-content__inputs-input file'>
                                <input
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className='modal-content__inputs-input file description'>
                                <span>Описание техники</span>
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    maxLength='350'
                                />
                            </div>
                            <p>Максимум 350 символов.</p>
                            <div className='error-vih'>
                                <p>{error}</p>
                            </div>
                        </div>
                        <div className='modal-content__btns'>
                            <div className='modal-content__btns-btn cancel'>
                                <button onClick={() => { setModalOpen(false); setEditingVehicle(null); }}>Отменить</button>
                            </div>
                            <div className='modal-content__btns-btn add'>
                                <button onClick={handleUpload} disabled={uploading}>
                                    {uploading ? 'Загрузка...' : (editingVehicle ? 'Сохранить' : 'Добавить')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isConfirmingDelete && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content__inputs'>
                            <h3>Вы уверены, что хотите удалить?</h3>
                        </div>
                        <div className='modal-content__btns delete'>
                            <div className='modal-content__btns-btn cancel'>
                                <button onClick={() => setIsConfirmingDelete(false)}>Отменить</button>
                            </div>
                            <div className='modal-content__btns-btn add'>
                                <button onClick={handleDelete}>Удалить</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MVehicles;
