import React, {useEffect, useState} from 'react';
import firestore from '../../../action/firestore';
import 'firebase/compat/storage';
import Title from "../../title";
import './styles/styles.css';
import './styles/@styles.css';
import Carousel from "../../carousel";

function Vehicles () {
    const [vehicleData, setVehicleData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchVehicleData = async () => {
            try {
                const snapshot = await firestore.collection('vehicles').get();
                const data = snapshot.docs.map(doc => {
                    const docData = doc.data();
                    console.log('Document data:', docData);
                    return {
                        url: docData.urlMedia,
                        description: docData.description
                    };
                });
                console.log('Fetched data:', data);
                setVehicleData(data);
            } catch (error) {
                console.error('Error fetching vehicle data: ', error);
            }
        };

        fetchVehicleData();
    }, []);

    return(
        <div className='vehicles' id='technique'>
            <div className='vehicles-container'>
                <Title text='Техника'/>
                <div className='vehicles-container__content'>
                    <div className='vehicles-container__content-carousel'>
                        <Carousel
                            slides={vehicleData.map(item => item.url)}
                            setCurrentIndex={setCurrentIndex}
                        />
                    </div>
                    <div className='vehicles-container__content-description'>
                        {vehicleData.length > 0 && (
                            <span>{vehicleData[currentIndex].description}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vehicles;
