import React, { useEffect, useState } from 'react';
import 'firebase/compat/firestore';
import firestore from "../../../action/firestore";
import CarouselVideo from "../../carousel_video";
import './styles/styles.css';
import './styles/@styles.css';
// import firebase from 'firebase/compat/app';

function BestVideo() {
    const [videos, setVideos] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchMomentsData = async () => {
            try {
                const snapshot = await firestore.collection('videos').orderBy('timestamp', 'desc').get();
                const data = snapshot.docs.map(doc => {
                    const docData = doc.data();
                    console.log('Document video data:', docData);
                    return {
                        id: doc.id,
                        url: docData.urlVideo,
                    };
                });
                console.log('Fetched video data:', data);
                setVideos(data);
            } catch (error) {
                console.error('Error fetching video data: ', error);
            }
        };

        fetchMomentsData();
    }, []);

    return (
        <div className='video'>
            <div className='video-container'>
                <div className='video-container__carousel'>
                    {videos.length > 0 && (
                        <CarouselVideo
                            slides={videos.map(video => (
                                <div key={video.id} className='carousel-slide'>
                                    <iframe
                                        src={video.url.replace("watch?v=", "embed/")}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="YouTube Video Player"
                                        className='carousel-video'
                                    ></iframe>
                                </div>
                            ))}
                            setCurrentIndex={setCurrentIndex}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default BestVideo;
