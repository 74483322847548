import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import Logo from '../../../assets/images/logo.svg'
import Burger from "./burger_menu";
import './styles/styles.css'
import './styles/@styles.css'

function Header () {
    const navigate = useNavigate();

    const handleNavigation = (path, id) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    const offset = 30;
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }, 100);
        }
    };

    return(
        <div className='header'>
            <div className='header-container'>
                <div className='header-container__logo'>
                    <Link to='/'>
                        <img
                            src={Logo}
                            alt='Logo'
                        />
                    </Link>
                </div>
                <div className='header-container__links'>
                    <span onClick={() => handleNavigation('/', 'about')}>Наш клуб</span>
                    <span onClick={() => handleNavigation('/', 'team')}>Команда</span>
                    <span onClick={() => handleNavigation('/', 'tariffs')}>Тарифы</span>
                    <span onClick={() => handleNavigation('/', 'technique')}>Техника</span>
                    <span onClick={() => handleNavigation('/', 'development')}>Развитие</span>
                    <span onClick={() => handleNavigation('/', 'contacts')}>Контакты</span>
                </div>
                <div className='header-container__burger'>
                    <Burger/>
                </div>
            </div>
        </div>
    )
}

export default Header;
