import React from 'react';
import Logo from '../../../assets/images/logo.svg';
import './styles/styles.css';
import './styles/@styles.css';

function AboutUs () {

    return(
        <div className='about' id='about'>
            <div className='about-container'>
                <div className='about-container__logo'>
                    <img
                        src={Logo}
                        alt='Logo'
                    />
                </div>
                <div className='about-container__title'>
                    <span>Добро пожаловать в мир <span>Enduro Crimea!</span></span>
                </div>
                <div className='about-container__description'>
                    <span>
                        Enduro Crimea - это не просто мотоспорт,
                        это страсть, дружба и приключения!
                        Мы создаем незабываемые впечатления для всех
                        любителей экстремальных ощущений.
                    </span>
                    <span>
                        Присоединяйтесь к нашей команде профессионалов
                        и начинайте свое увлекательное путешествие по
                        горным тропам и бездорожью. Мы гарантируем
                        яркие эмоции и безопасность на каждом этапе!
                    </span>
                    <span>
                        Не упустите возможность стать частью нашей большой
                        семьи мотоэнтузиастов. Enduro Crimea - ваш путь к
                        новым горизонтам мотоспорта!
                    </span>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;
