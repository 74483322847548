import React, {useEffect, useState} from 'react';
import firestore from "../../../action/firestore";
import Carousel from "../../carousel";
import Title from "../../title";
import './styles/styles.css'
import './styles/@styles.css'

function BestMoments() {
    const [moments, setMoments] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchMomentsData = async () => {
            try {
                const snapshot = await firestore.collection('moments').get();
                const data = snapshot.docs.map(doc => {
                    const docData = doc.data();
                    console.log('Document data:', docData);
                    return {
                        url: docData.media,
                    };
                });
                console.log('Fetched data:', data);
                setMoments(data);
            } catch (error) {
                console.error('Error fetching moments data: ', error);
            }
        };

        fetchMomentsData();
    }, []);

    return(
        <div className='moments' id='moments'>
            <div className='moments-container'>
                <Title text='Лучшие моменты'/>
                <div className='moments-container__description'>
                    <span>
                        Ознакомьтесь с нашими лучшими моментами:
                        захватывающие пейзажи, дружеская атмосфера и многое другое!
                    </span>
                </div>
                <div className='moments-container__carousel'>
                    <Carousel
                        slides={moments.map(item => item.url)}
                        setCurrentIndex={setCurrentIndex}
                    />
                </div>
            </div>
        </div>
    )
}

export default BestMoments;
