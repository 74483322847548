import React from 'react';
import Title from '../../title';
import Location from '../../../assets/images/training/best_location.svg';
import Individual from '../../../assets/images/training/individual.svg';
import Necessary from '../../../assets/images/training/necessary.svg';
import Moto from '../../../assets/images/training/moto.svg';
import Equipment from '../../../assets/images/training/equipment.svg';
import './styles/styles.css';
import './styles/@styles.css';

function Training () {

    return(
        <div className='training'>
            <div className='training-container'>
                <Title text='Тренировки'/>
                <div className='training-container__content'>
                    <div className='training-container__content-item'>
                        <img src={Location} alt='Location'/>
                        <span>Тренировки в лучших локациях горного Крыма.</span>
                    </div>
                    <div className='training-container__content-item'>
                        <img src={Individual} alt='Individual'/>
                        <span>Индивидуальная программа тренировок с учетом ваших особенностей и целей</span>
                    </div>
                    <div className='training-container__content-item'>
                        <img src={Necessary} alt='Necessary'/>
                        <span>Все необходимое для тренировок и отдыха.</span>
                    </div>
                    <div className='training-container__content-item'>
                        <img src={Moto} alt='Moto'/>
                        <span>Подберём и предоставим мотоцикл под любой возраст, рост и задачи</span>
                    </div>
                    <div className='training-container__content-item'>
                        <img src={Equipment} alt='Equipment'/>
                        <span>Полный комплект защитной экипировки уже входит в стоимость тренировки</span>
                    </div>
                    <div className='empty-item-training'></div>
                </div>
            </div>
        </div>
    )
}

export default Training;
