import React from 'react';
import {useNavigate} from "react-router-dom";
import './styles/styles.css';
import './styles/@styles.css';

function StartScreen () {
    const navigate = useNavigate();

    const handleNavigation = (path, id) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    const offset = 30;
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }, 100);
        }
    };

    return(
        <div className='start'>
            <div className='start-container'>
                <div className='start-container__title'>
                    <h2>По лесам<span>вместе с нами</span></h2>
                </div>
                <div className='start-container__description'>
                    <span>Стань частью нашей команды и погрузись в мир мотоспорта вместе с нами!</span>
                </div>
                <div className='start-container__button'>
                    <button onClick={() => handleNavigation('/', 'callback')}>
                        <span>Присоединиться</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default StartScreen;
