import React, {useState} from 'react';
import Title from "../../title";
import Popup from "../popup";
import './styles/styles.css';
import './styles/@styles.css';

function CallBack() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [errors, setErrors] = useState({ name: '', phone: '' });

    const TELEGRAM_BOT_TOKEN = "7344299104:AAHMZBEDsE3hZYT7W19lfuqZJ04MtUhMwhk"
    const TELEGRAM_CHAT_ID = "-1002149338568"
    const API = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`

    const ERROR_MESSAGES = {
        name: 'Имя обязательно для заполнения',
        phone: 'Телефон обязателен для заполнения'
    }

    async function sendEmailTelegram(name, email, phone, message) {
        const text = `${name} оставил(а) заявку!\nEmail: ${email}\nТелефон: ${phone}\nСообщение: ${message}`;
        try {
            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    chat_id: TELEGRAM_CHAT_ID,
                    text
                })
            });
            if (response.ok) {
                console.log('Successful');
                setModalMessage('Заявка успешно отправлена!');
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
                setErrors({ name: '', phone: '' });
            } else {
                setModalMessage('Ошибка при отправке заявки. Попробуйте позже.');
            }
            setShowModal(true);
        } catch (error) {
            console.error(error);
        }
    }
    const formatPhone = (input) => {
        let cleaned = ('' + input).replace(/\D/g, '');
        if (cleaned.charAt(0) !== '7') {
            cleaned = '7' + cleaned.substring(1);
        }
        let match = cleaned.match(/^(\d{1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/);
        if (match) {
            cleaned = '+' + match[1] + (match[2] ? `(${match[2]}` : '') + (match[3] ? `) ${match[3]}` : '') + (match[4] ? `-${match[4]}` : '') + (match[5] ? `-${match[5]}` : '');
        }
        return cleaned;
    };
    const handleChangePhone = (event) => {
        let inputPhone = event.target.value;
        if (inputPhone === '' || /^\+7\(\d{0,3}$/.test(inputPhone)) {
            setPhone(inputPhone);
            return;
        }
        if (inputPhone.length > 17) {
            inputPhone = inputPhone.slice(0, 17);
        }
        const formattedPhone = formatPhone(inputPhone);
        setPhone(formattedPhone);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        let newErrors = { name: '', phone: '' };
        if (!name) {
            newErrors.name = ERROR_MESSAGES.name;
        }
        if (!phone) {
            newErrors.phone = ERROR_MESSAGES.phone;
        }
        setErrors(newErrors);
        if (!newErrors.name && !newErrors.phone) {
            await sendEmailTelegram(name, email, phone, message);
        }
    };
    const closeModal = () => {
        setShowModal(false);
    };

    return(
        <div className='callback' id='callback'>
            <div className='callback-container'>
                <Title text='Связаться с нами'/>
                <div className='callback-container__form'>
                    <div className='callback-container__form-inputs'>
                        <div className='callback-container__form-inputs__input'>
                            <input
                                type='text'
                                name='name'
                                value={name}
                                placeholder='Имя'
                                onChange={(e) => setName(e.target.value)}
                                autoComplete='off'
                            />
                            {errors.name && <div className="error-text">{errors.name}</div>}
                        </div>
                        <div className='callback-container__form-inputs__input textarea'>
                            <textarea
                                name='message'
                                value={message}
                                placeholder='Сообщение'
                                onChange={(e) => setMessage(e.target.value)}
                                autoComplete='off'
                            />
                        </div>
                    </div>
                    <div className='callback-container__form-inputs'>
                        <div className='callback-container__form-inputs__input'>
                            <input
                                type='email'
                                name='email'
                                value={email}
                                placeholder='E-mail'
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete='off'
                            />
                        </div>
                        <div className='callback-container__form-inputs__input'>
                            <input
                                type='tel'
                                value={phone}
                                name='phone'
                                onChange={handleChangePhone}
                                placeholder='+7(999)999-99-99'
                                autoComplete='off'
                            />
                            {errors.phone && <div className="error-text">{errors.phone}</div>}
                        </div>
                        <button onClick={handleSubmit}>Отправить</button>
                    </div>
                </div>
            </div>
            <Popup show={showModal} message={modalMessage} onClose={closeModal} />
        </div>
    )
}

export default CallBack;
