import React, { useEffect, useState } from 'react';
import firestore from '../../../action/firestore';
import 'firebase/compat/storage';
import Add from '../../../assets/images/icons/add.svg';
import Edit from '../../../assets/images/icons/edit.svg';
import Delete from '../../../assets/images/icons/delete.svg';
import './styles/styles.css';
import './styles/@styles.css';

function MPrice () {
    const [prices, setPrices] = useState([]);
    const [editingPrice, setEditingPrice] = useState(null);
    const [newData, setNewData] = useState({ name: '', description: '', price: null });
    const [isAdding, setIsAdding] = useState(false);
    const [newPriceData, setNewPriceData] = useState({ name: '', description: '', price: null });
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [priceToDelete, setPriceToDelete] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const priceCollection = await firestore.collection('price').get();
                const priceList = priceCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPrices(priceList);
            } catch (error) {
                console.error("Error fetching prices: ", error);
            }
        };

        fetchPrices();
    }, []);

    const getNextId = async () => {
        const counterDoc = firestore.collection('counters').doc('priceCounter');
        const newId = await firestore.runTransaction(async (transaction) => {
            const doc = await transaction.get(counterDoc);
            if (!doc.exists) {
                transaction.set(counterDoc, { currentId: 1 });
                return 1;
            }
            const newValue = doc.data().currentId + 1;
            transaction.update(counterDoc, { currentId: newValue });
            return newValue;
        });
        return newId;
    };

    const validateFields = (data) => {
        if (!data.name || !data.description) {
            setError('Поля "Название услуги" и "Описание услуги" обязательны для заполнения.');
            return false;
        }
        setError('');
        return true;
    };

    const addNewPrice = async () => {
        if (!validateFields(newPriceData)) {
            return;
        }

        try {
            const nextId = await getNextId();
            await firestore.collection('price').doc(nextId.toString()).set(newPriceData);
            setPrices([...prices, { id: nextId.toString(), ...newPriceData }]);
            setIsAdding(false);
            setNewPriceData({ name: '', description: '', price: null });
        } catch (error) {
            console.error("Error adding new price: ", error);
        }
    };

    const startEditing = (price) => {
        setEditingPrice(price.id);
        setNewData({ name: price.name, description: price.description, price: price.price });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setNewData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const saveEdit = async (id) => {
        if (!validateFields(newData)) {
            return;
        }

        try {
            await firestore.collection('price').doc(id).update(newData);
            setPrices(prices.map(price => price.id === id ? { ...price, ...newData } : price));
            setEditingPrice(null);
        } catch (error) {
            console.error("Error updating price: ", error);
        }
    };

    const confirmDeletePrice = (id) => {
        setPriceToDelete(id);
        setIsConfirmingDelete(true);
    };

    const deletePrice = async () => {
        try {
            await firestore.collection('price').doc(priceToDelete).delete();
            setPrices(prevPrices => prevPrices.filter(price => price.id !== priceToDelete));
            setIsConfirmingDelete(false);
            setPriceToDelete(null);
        } catch (error) {
            console.error("Error deleting price: ", error);
        }
    };

    const handleNewPriceChange = (e) => {
        const { name, value } = e.target;
        const newValue = name === 'price' ? (value === '' ? null : parseFloat(value)) : value;
        setNewPriceData(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };

    const closeModal = () => {
        setIsAdding(false);
        setNewPriceData({ name: '', description: '', price: null });
    };

    return (
        <div className='mprice'>
            <div className='mprice-container'>
                <div className='mtitle'>
                    <span>Цены и Услуги</span>
                    <div className='mprice-container__add'>
                        <div className='mprice-container__add-btn'>
                            <button onClick={() => setIsAdding(true)}>
                                <span>Добавить</span>
                                <img src={Add} alt='Add'/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='mprice-container__content'>
                    {prices.map(price => (
                        <div key={price.id} className='mprice-container__content-item'>
                            {editingPrice === price.id ? (
                                <div className='mprice-container__content-item__inputs'>
                                    <div className='mprice-container__content-item__inputs-input name'>
                                        <span>Название услуги</span>
                                        <input
                                            type="text"
                                            name="name"
                                            value={newData.name}
                                            maxLength='35'
                                            onChange={handleEditChange}
                                            autoComplete='off'
                                        />
                                    </div>
                                    <p>Максимум 35 символов.</p>
                                    <div className='mprice-container__content-item__inputs-input description'>
                                        <span>Описание услуги</span>
                                        <textarea
                                            name="description"
                                            value={newData.description}
                                            maxLength='180'
                                            onChange={handleEditChange}
                                            autoComplete='off'
                                        />
                                    </div>
                                    <p>Максимум 180 символов.</p>
                                    <div className='mprice-container__content-item__inputs-input price'>
                                        <span>Стоимость услуги</span>
                                        <input
                                            type="number"
                                            name="price"
                                            value={newData.price}
                                            onChange={handleEditChange}
                                            autoComplete='off'
                                        />
                                    </div>
                                    {error && <div className='error-pr'><p>{error}</p></div>}
                                    <button onClick={() => saveEdit(price.id)}>Сохранить</button>
                                </div>
                            ) : (
                                <div className='mprice-container__content-item__card'>
                                    <div className='mprice-container__content-item__card-content'>
                                        <div className='mprice-container__content-item__card-content__title'>
                                            <div className='mprice-container__content-item__card-content__title-text'>
                                                <h3>{price.name}</h3>
                                            </div>
                                            <div className='mprice-container__content-item__card-content__title-btns'>
                                                <button onClick={() => startEditing(price)}><img src={Edit} alt='Edit'/></button>
                                                <button onClick={() => confirmDeletePrice(price.id)}><img src={Delete} alt='Delete' /></button>
                                            </div>
                                        </div>
                                        <div className='mprice-container__content-item__card-content__description'>
                                            <span>{price.description}</span>
                                        </div>
                                        {price.price && (
                                            <div className='mprice-container__content-item__card-content__price'>
                                                <span>от {price.price} ₽/день</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {isAdding && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content__inputs'>
                            <h3>Добавить новую услугу</h3>
                            <div className='modal-content__inputs-input name'>
                                <span>Название услуги</span>
                                <input
                                    type="text"
                                    name="name"
                                    maxLength='35'
                                    value={newPriceData.name}
                                    onChange={handleNewPriceChange}
                                    autoComplete='off'
                                />
                            </div>
                            <p>Максимум 35 символов.</p>
                            <div className='modal-content__inputs-input description'>
                                <span>Описание услуги</span>
                                <textarea
                                    name="description"
                                    value={newPriceData.description}
                                    maxLength='180'
                                    onChange={handleNewPriceChange}
                                    autoComplete='off'
                                />
                            </div>
                            <p>Максимум 180 символов.</p>
                            <div className='modal-content__inputs-input price'>
                                <span>Цена услуги</span>
                                <input
                                    type="number"
                                    name="price"
                                    value={newPriceData.price}
                                    onChange={handleNewPriceChange}
                                    autoComplete='off'
                                />
                            </div>
                            {error && <div className='error-pr'><p>{error}</p></div>}
                        </div>
                        <div className='modal-content__btns'>
                            <div className='modal-content__btns-btn cancel'>
                                <button onClick={closeModal}>Отменить</button>
                            </div>
                            <div className='modal-content__btns-btn add'>
                                <button onClick={addNewPrice}>Добавить</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isConfirmingDelete && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content__inputs'>
                            <h3>Вы уверены, что хотите удалить эту услугу?</h3>
                        </div>
                        <div className='modal-content__btns delete'>
                            <div className='modal-content__btns-btn cancel'>
                                <button onClick={() => setIsConfirmingDelete(false)}>Отменить</button>
                            </div>
                            <div className='modal-content__btns-btn add'>
                                <button onClick={deletePrice}>Удалить</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MPrice;
