import React, {useEffect, useState} from 'react';
import './styles/styles.css';
import {Link, useNavigate} from "react-router-dom";

function Burger() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    const handleNavigation = (path, id) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    const offset = 30;
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
                setIsOpen(false);
            }, 100);
        }
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div className={`burger-button ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <nav className={`burger-menu ${isOpen ? 'open' : ''}`}>
                <span className="menu-item" onClick={() => handleNavigation('/', 'about')}>Наш клуб</span>
                <span className="menu-item" onClick={() => handleNavigation('/', 'team')}>Команда</span>
                <span className="menu-item" onClick={() => handleNavigation('/', 'tariffs')}>Тарифы</span>
                <span className="menu-item" onClick={() => handleNavigation('/', 'technique')}>Техника</span>
                <span className="menu-item" onClick={() => handleNavigation('/', 'development')}>Развитие</span>
                <span className="menu-item" onClick={() => handleNavigation('/', 'contacts')}>Контакты</span>
            </nav>
        </div>
    );
}

export default Burger;
