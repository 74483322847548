import React from 'react';
import {YMaps, Map, ZoomControl, Placemark} from '@pbe/react-yandex-maps';
import Title from "../../title";
import './styles/styles.css';
import './styles/@styles.css';

function Contact () {

    return(
        <div className='contacts' id='contacts'>
            <div className='contacts-container'>
                <Title text='Контакты'/>
                <div className='contacts-container__content'>
                    <div className='contacts-container__content-contacts'>
                        <div className='contacts-container__content-contacts__item phone'>
                            <p>Tелефон:</p>
                            <a href='tel:79787454535'>+7 (978) 745-4535</a>
                        </div>
                        <div className='contacts-container__content-contacts__item tg'>
                            <p>Telegram:</p>
                            <a href='https://t.me/MaxShachnev' target='_blank' rel='noreferrer'>@MaxShachnev</a>
                        </div>
                        <div className='contacts-container__content-contacts__item email'>
                            <p>Email:</p>
                            <a href='mailto:crimea.enduro@mail.ru'>crimea.enduro@mail.ru</a>
                        </div>
                        <div className='contacts-container__content-contacts__item address'>
                            <p>Aдрес:</p>
                            <span>Крым, пгт. Куйбышево, ул. Советская, 18</span>
                        </div>
                        <div className='contacts-container__content-contacts__item time'>
                            <p>Pабочее время:</p>
                            <span>Пн-Вс: 10:00 - 18:00</span>
                        </div>
                    </div>
                    <div className='contacts-container__content-map'>
                        <YMaps>
                            <Map className='contacts-container__content-map__place'
                                 defaultState={{ center: [44.632981726933224,33.86325345337295], zoom: 16 }}>
                                <ZoomControl options={{float: 'right'}}/>
                                <Placemark geometry={[44.632981726933224,33.86325345337295]} />
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;
