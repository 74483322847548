import React, {useEffect, useState} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import Add from "../../../assets/images/icons/add.svg";
import Delete from "../../../assets/images/icons/delete_white.svg";
import './styles/styles.css';

function MBestVideos () {
    const [videos, setVideos] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [error, setError] = useState('');
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [videoToDelete, setVideoToDelete] = useState(null);

    useEffect(() => {
        const fetchVideos = async () => {
            const snapshot = await firebase.firestore().collection('videos').orderBy('timestamp', 'desc').get();
            const videoList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setVideos(videoList);
        };

        fetchVideos();
    }, [videos]);

    const addVideo = async () => {
        if (!videoUrl) {
            setError('Пожалуйста, предоставьте ссылку на видео.');
            return;
        }

        try {
            await firebase.firestore().collection('videos').add({
                urlVideo: videoUrl,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            });
            console.log('Видео успешно добавлено!');
            closeModal();
        } catch (error) {
            console.error('Ошибка при добавлении видео: ', error);
            setError('Ошибка при добавлении видео. Попробуйте еще раз.');
        }
    };
    const deleteVideo = async () => {
        try {
            await firebase.firestore().collection('videos').doc(videoToDelete).delete();
            console.log('Видео успешно удалено!');
            setIsConfirmingDelete(false);
            setVideoToDelete(null);
        } catch (error) {
            console.error('Ошибка при удалении видео: ', error);
        }
    };
    const handleInputChange = (e) => {
        setVideoUrl(e.target.value);
    };
    const openModal = () => {
        setIsAdding(true);
        setVideoUrl('');
        setError('');
    };
    const closeModal = () => {
        setIsAdding(false);
    };
    const confirmDelete = (id) => {
        setVideoToDelete(id);
        setIsConfirmingDelete(true);
    };
    const cancelDelete = () => {
        setIsConfirmingDelete(false);
        setVideoToDelete(null);
    };

    return(
        <div className='mvideo'>
            <div className='mvideo-container'>
                <div className='mtitle'>
                    <span>Видео</span>
                    <div className='mvehicles-container__add'>
                        <div className='mvehicles-container__add-btn'>
                            <button onClick={openModal}>
                                <span>Добавить</span>
                                <img src={Add} alt='Add' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='mvideo-container__content'>
                    {videos.map((video) => (
                        <div className='mvideo-container__content-item'>
                            <div key={video.id} className='mvideo-container__content-item'>
                                <iframe
                                    src={video.urlVideo.replace("watch?v=", "embed/")}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="YouTube Video Player"
                                ></iframe>
                                <button onClick={() => confirmDelete(video.id)}>
                                    <img src={Delete} alt='Delete' />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isAdding && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content__inputs'>
                            <h3>Добавить новое видео</h3>
                            <div className='modal-content__inputs-input video'>
                                <span>Ссылка на видео (YouTube)</span>
                                <input
                                    type="text"
                                    name="videoUrl"
                                    value={videoUrl}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                />
                            </div>
                            {error && <div className='error-video'><p>{error}</p></div>}
                        </div>
                        <div className='modal-content__btns'>
                            <div className='modal-content__btns-btn cancel'>
                                <button onClick={closeModal}>Отменить</button>
                            </div>
                            <div className='modal-content__btns-btn add'>
                                <button onClick={addVideo}>Добавить</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isConfirmingDelete && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content__inputs'>
                            <h3>Вы уверены, что хотите удалить это видео?</h3>
                        </div>
                        <div className='modal-content__btns delete'>
                            <div className='modal-content__btns-btn cancel'>
                                <button onClick={cancelDelete}>Отменить</button>
                            </div>
                            <div className='modal-content__btns-btn add'>
                                <button onClick={deleteVideo}>Удалить</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MBestVideos;
