import React from 'react';
import './styles/styles.css';

const Title = ({ text }) => {

    return (
        <div className='title'>
            <span>
                {text}
            </span>
        </div>
    );
};

export default Title;
