import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from "./header";
import StartScreen from "./start_screen";
import AboutUs from "./about_us";
import OurTeam from "./our_team";
import Price from "./price";
import Training from "./training";
import Vehicles from "./vehecles";
import Tutorial from "./tutorial";
import BestMoments from "./best_moments";
import BestVideo from "./best_video";
import CallBack from "./callback";
import Contact from "./contacts";
import Footer from "./footer";

function Landing () {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname.substring(1);
        if (path) {
            const element = document.getElementById(path);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }}, [location]);

    return(
        <>
            <Header/>
            <StartScreen/>
            <AboutUs id='about' />
            <OurTeam id='team'/>
            <Price id='tariffs'/>
            <Training/>
            <Vehicles id='technique'/>
            <Tutorial id='development'/>
            <BestMoments id='moments'/>
            <BestVideo/>
            <CallBack id='callback'/>
            <Contact id='contacts'/>
            <Footer/>
        </>
    )
}

export default Landing;
