import React, { useState } from 'react';
import MPrice from "../price";
import MVehicles from "../vehicles";
import MBestMoments from "../best_moments";
import MBestVideos from "../best_videos";
import './styles/styles.css';
import './styles/@styles.css';

function ControlPanel() {
    const [selectedItem, setSelectedItem] = useState('price');

    const renderContent = () => {
        switch (selectedItem) {
            case 'price':
                return <MPrice />;
            case 'vehicles':
                return <MVehicles />;
            case 'photos':
                return <MBestMoments />;
            case 'videos':
                return <MBestVideos />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className='control'>
                <div className='control-container'>
                    <div className='control-container__content'>
                        <div
                            className={`control-container__content-item ${selectedItem === 'price' ? 'selected' : ''}`}
                            onClick={() => setSelectedItem('price')}
                        >
                            <span>Цены и Услуги</span>
                        </div>
                        <div
                            className={`control-container__content-item ${selectedItem === 'vehicles' ? 'selected' : ''}`}
                            onClick={() => setSelectedItem('vehicles')}
                        >
                            <span>Техника</span>
                        </div>
                        <div
                            className={`control-container__content-item ${selectedItem === 'photos' ? 'selected' : ''}`}
                            onClick={() => setSelectedItem('photos')}
                        >
                            <span>Фотоальбом</span>
                        </div>
                        <div
                            className={`control-container__content-item ${selectedItem === 'videos' ? 'selected' : ''}`}
                            onClick={() => setSelectedItem('videos')}
                        >
                            <span>Видео</span>
                        </div>
                    </div>
                </div>
                <div className='control-content'>
                    {renderContent()}
                </div>
            </div>
            <div className='no-mobile'>
                <p>Пожалуйста, зайдите в <span>dashboard</span> с компьютера</p>
            </div>
        </>
    );
}

export default ControlPanel;
