import React from 'react';
import Title from "../../title";
import AboutTeam from '../../../assets/images/our_team/our_team.svg';
import Coach from '../../../assets/images/our_team/coach.svg';
import './styles/styles.css';
import './styles/@styles.css';

function OurTeam () {

    return(
        <div className='team' id='team'>
            <div className='team-container'>
                <Title text='Наша Команда'/>
                <div className='team-container__about'>
                    <img
                        src={AboutTeam}
                        alt='AboutTeam'
                    />
                    <div className='team-container__about-description'>
                        <span>
                            Мы - спортсмены, путешественники, профессионалы и
                            настоящие друзья. Наше сообщество — это не просто
                            группа людей, а сплоченная команда, готовая преодолевать
                            любые препятствия вместе и делиться радостью от достижений.
                        </span>
                        <span>
                            Мы обожаем мотоспорт и готовы поделиться своими знаниями с вами!
                            Наш опыт и экспертиза в мире мотоспорта — это не только
                            источник вдохновения, но и ценный ресурс для всех, кто
                            разделяет нашу любовь к адреналину и азарту гонок.
                        </span>
                        <span>
                            Присоединяйтесь к нам и станьте частью нашей большой семьи.
                        </span>
                    </div>
                </div>
                <div className='team-container__coach'>
                    <div className='team-container__coach-description'>
                        <span>
                            <span>Шачнев Максим</span> - профессиональный мотогонщик
                            из Крыма с более чем 10-летним опытом в эндуро.
                        </span>
                        <span>
                            Он является многократным чемпионом на эндуро соревнованиях
                            и уважаемым организатором выездных тренировок.
                        </span>
                        <span>
                            Благодаря своему опыту и мастерству, Максим
                            обучил и воспитал более 10 гонщиков, помогая
                            им достичь выдающихся результатов.
                        </span>
                        <span>
                            Ежегодно он готовит гонщиков к сложным соревнованиям,
                            делясь своими знаниями и опытом, чтобы помочь другим
                            спортсменам достичь успехов в мире мотоспорта.
                        </span>
                    </div>
                    <img
                        src={Coach}
                        alt='Coach'
                    />
                </div>
            </div>
        </div>
    )
}

export default OurTeam;
